.main{
    margin-top: 150px;
    margin-bottom: 150px;
}

.firstTxt{
    margin: 0 auto ;
    width: 65%;
    padding-bottom: 20px;
    font-size: 18px;
    text-align: center;
    text-align: start;
    line-height: 30px;
}

.background{
    background-color: var(--background-color);
    padding: 0.2% 0;
}

@media screen and (max-width: 500px) {

    .main{
        margin-top: 60px;
        margin-bottom: 30px;
    }
    .firstTxt{
        width: 70%;
        font-size: 15px;
    }
}
