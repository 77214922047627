.usluge{
    margin: 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-family: 'Montserrat',sans-serif;   
    
}

.title{
    font-size: 40px;
    font-weight:bolder;
    margin-bottom: 60px;
}

.main{
    width: 80%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
 
}


.column{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0px 10px;
    padding-bottom: 30px;
}



.title2{
    font-size: 18px;
}

.button{
    background-color: var(--main-color);
    border: none;
    border-radius: 30px;
    text-align: center;
    text-decoration: none;
    width: 200px;
    font-size: 16px;
    padding: 13px 30px;
    color: white;
    font-size: 17px;
    font-weight: medium;
    cursor: pointer;
}

.parent{
    display: flex;
    justify-content: end;
    width: 80%;
    margin-bottom: 10vh;
}

.icon{
    width: 100%;
}

@media (max-width:768px){


    .title{
        text-align: center;
        font-size: 40px;
    }
    .main{
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .parent{
        justify-content: center;
    }

}