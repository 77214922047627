.kontakt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 130px;


}

.location {
    margin-bottom: 50px;
}

.line {
    width: 30%;
}


@media (max-width:972px) {

    .location {
        max-width: 80%;
        display: flex;
        justify-content: center;
    }
}