.usluge{
    display: flex;
    /* justify-content: center;
    flex-wrap: wrap; */
    margin-top: 40px;
    margin-bottom: 40px;
}

.main{
    width: 90%;
    margin: 0 auto;
    margin-bottom: 80px;
}

@media (max-width:972px){
    .usluge{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .title{
        width: 80%;
        margin: 0 auto;
    }
}