.main {
    font-family: 'Montserrat', sans-serif;
}

.navbar {
    background-color: rgba(255, 255, 255, 0.4);
    width: 100vw;
    height: 85px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 2px solid #e1e4e5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.navbarActive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    position: sticky;
    top: 0;
    width: 100vw;
    height: 100px;
    background-color: white;
    backdrop-filter: blur(10px);
    margin-bottom: 300px;
}

.logo {
    padding: 0 100px;
    max-height: 100px;
    height: auto;
}

.navbarLeft {
    width: 40%;
}

.navbarList {
    list-style: none;
    margin-right: 20px;
    font-weight: bold;

}

nav .navbarList li {
    display: inline-block;
    line-height: 80px;
    margin: 0 5px;
    text-transform: uppercase;
}


.active {
    color: black;
    font-size: 14px;
    border-bottom: var(--main-color) 2px solid;
    padding: 7px 13px;
}

.inactive {
    color: black;
    font-size: 14px;
    padding: 7px 13px;
}

.checkbtn {
    font-size: 30px;
    color: black;
    display: flex;
    justify-content: flex-end;
    margin-right: 40px;
    cursor: pointer;
    display: none;
}

.check {
    display: none;
}


@media(max-width:1184px) {

    .logo {
        padding-left: 50px;
    }

    .active {
        font-size: 16px;

    }

    .inactive {
        font-size: 16px;

    }

    .navbarList {
        padding: 0px;
        margin-right: 10px;

    }
}

@media(max-width:981px) {

    .checkbtn {
        display: block;
    }

    .navbarList {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: var(--main-color);
        left: -100%;
        text-align: center;
        transition: all .5s;
    }

    .logo {
        max-height: 70px;
    }

    nav .navbarList li {
        display: block;
    }

    .active {
        font-size: 20px;
    }

    .inactive {
        font-size: 20px;
    }


    #check:checked~.navbarList {
        left: 0;
    }
}