.header {
    min-height: 80%;
    background: url('../../../assets/naslovna.png');
    background-size: cover;
    background-position: center;
}

.text {
    color: white;
    display: flex;
    height: 550px;
    padding-left: 100px;
    flex-direction: column;
}

.title {
    border-left: 3px solid var(--main-color);
    padding-left: 10px;
    padding-right: 50px;
    margin-bottom: 10px;
    margin-top: 100px;
    font-size: 35px;
    font-weight: 600;
    line-height: 1.5em;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 40px;
}

.title2 {
    border-left: 3px solid var(--main-color);
    padding-left: 10px;
    font-size: 35px;
    margin-top: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}


.numbers {
    display: flex;
    justify-content: end;
    margin-right: 20%;
}

.projectsNumber {
    width: 150px;
    background-color: var(--dark-color);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    word-wrap: break-word;
}

.yearNumber {
    width: 150px;
    background-color: var(--main-color);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
}

.num {
    margin: 0;
    font-size: 35px;
    font-weight: 700;
    font-family: 'Rubik';
}

.txt {
    font-size: 16px;
    text-align: center;

}

.buttons {
    margin-top: 100px;
    display: flex;
    width: 23vw;
    justify-content: space-between;
}

.button1 {
    width: 150px;
    height: 45px;
    background-color: var(--dark-color);
    border: none;
    color: white;
    border-radius: 30px;
    font-size: 14px;
    cursor: pointer;
}

.button2 {
    width: 150px;
    height: 45px;
    border-radius: 30px;
    color: var(--dark-color);
    border: none;
    padding: 13px 30px;
    font-size: 14px;
    cursor: pointer;
}

@media screen and (max-width: 900px) {

    .title {
        font-size: 28px;
        margin-top: 70px;
        font-weight: 600;
        width: 95vw;

    }

    .title2 {
        font-size: 28px;
        font-weight: 600;
        width: 95vw;
    }

    .numbers {
        width: 100%;
    }

    .yearNumber,
    .projectsNumber {
        width: 50%;
    }

    .text {
        padding-left: 10px;
    }

    .buttons {
        margin-top: 40px;
    }

    .button1 {
        margin-right: 20px;
    }
}

@media screen and (max-width: 445px) {
    .title {
        font-size: 23px;
    }

    .title2 {
        font-size: 23px;
    }


}