.usluge{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 130px;
}


@media (max-width:972px){
    .usluge{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }

}
