.footer{
    background-color: var(--dark-color);
    color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 80px;
    left: 0;
    bottom: 0;
    width: 100%;
}
.Logo{
    max-width: 120%;
    background-color: white;
    margin: 50px 0;  
}

.title2{
    font-weight:900;
    font-size: 20px;
    line-height: 40px;
}

.column{
    flex:1;
    padding: 0 10px;
    margin:0  8%;
}

.title{
    border-bottom: 1px solid var(--main-color);
    margin-bottom: 10px;
    width: 80%;
    font-size: 25px;
}

@media screen and (max-width: 768px) {
    .footer {
      flex-direction: column; 
      align-items: center; 
      margin: 0 auto;
    }

    
    .column {
    display: flex;
    flex-direction: column;
    }

    .Logo{
        max-width: 50%;
        align-self: center;
    }
    
    .column {
      width: 100%;
    }

    .text{
        font-size: 14px;
        line-height: 10px;
    }

    .title2{
        font-size: 17px;
        line-height: 30px;
        font-weight:900;
    }


  }