.usluge{
    background-color: var(--background-color);
    margin-top: 100px; 
    padding-top: 70px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-family: 'Montserrat',sans-serif;
}

.title{
    font-size: 40px;
    font-weight:bolder;
    margin-bottom: 60px;
    
}

.main{
    width: 80%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
}


.column{
    width: 22%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0 50px;
}
/* .txt{
    font-family: poppins;
} */

.title2{
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 80px;
}


.button{
    background-color: var(--main-color);
    border: none;
    border-radius: 30px;
    text-align: center;
    text-decoration: none;
    width: 200px;
    font-size: 16px;
    padding: 13px 30px;
    color: white;
    font-size: 17px;
    font-weight: medium;
    cursor: pointer;
}

.parent{
    display: flex;
    justify-content: end;
    width: 80%;
    margin-bottom: 10vh;
}

@media (max-width:768px){
    .main{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .column{
        margin-bottom: 20px;
    }
    .parent{
        justify-content: center;
    }
    .title{
        font-size: 36px;
        line-height: 48px;
        width: 80vw;
        text-align: center;
    }

    .title2{
        font-size: 16px;
    }

    .column{    
       margin: 0 0;
    }
}