 .article{
    margin-top: 100px;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0px 3%;
}

.image{
    width: 100%;
    height: auto;
    margin-bottom:30px ;
}

.title{
    font-size: 25px;
    font-weight: 700;
    font-family: "poppins", sans-serif;
    margin: 0px;
}

.description{
    font-size: 17px;
    line-height: 30px;
     max-width: 100%; 
} 

 .info{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0px;
} 

     @media (max-width: 768px) {
    .article {
      margin-top: 50px;
      width: 90%;
      margin: 0 auto;
    }
  
    .title {
      width: 80%;
      margin: 0 auto;
      font-size: 20px; 
    }
  
    .description {
      font-size: 14px; 
      line-height: 20px;
      max-width: 90%;
    }
  
    .image {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 30px; 
  } 


}